
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import QuestionPreview from './QuestionPreview.vue'
  import { QuestionInterface, QuestionType, AnswerInterface } from '@/interface/BaseInterface'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'
  import ListAnswers from './ListAnswers.vue'
  import { forEach, trim, filter, includes } from 'lodash'
  import ChildQuestion from './ChildQuestion.vue'

  @Component({
    components: { QuestionPreview, BaseEditor, ListAnswers, ChildQuestion }
  })
  export default class CreateQuestion extends Vue {
    @Prop({ required: false, type: Boolean, default: false }) isLoading!: boolean
    @Prop({ required: false, type: Boolean, default: false }) modeEdit!: boolean
    @Prop({ required: false, type: String, default: '' }) parentId!: string

    showPreview = true
    content = ''

    questionType = QuestionType

    thisRegex = new RegExp(/\[\w+\]/, 'g')

    data: QuestionInterface = {
      _id: '',
      name: 'Question',
      type: QuestionType.MUTILCHOICE,
      content: '',
      tags: [],
      keys: [],
      point: 1,
      answers: []
    }

    handleChangeAnswer(data: AnswerInterface[]) {
      this.data.answers = data
    }

    setDataEdit(data: QuestionInterface) {
      this.reset()
      this.data = data
      this.setData()
    }

    reset() {
      this.data = {
        _id: '',
        name: 'Question',
        type: QuestionType.MUTILCHOICE,
        content: '',
        tags: [],
        keys: [],
        point: 1,
        answers: []
      }
    }

    setData() {
      if (this.$refs.baseEditor) {
        // @ts-ignore
        this.$refs.baseEditor.setData(this.data.content)
      }
    }

    handleChange(text: string) {
      this.data.content = text

      if (trim(text) !== '' && QuestionType.FILL_IN_MULTIPLE) {
        const result = text.match(this.thisRegex)

        this.data.keys = []
        forEach(result, value => {
          const a = value.match(new RegExp(/\w+/, 'g'))

          if (a) {
            this.data.keys?.push(a[0])
          }
        })
      }
    }

    handleSubmit() {
      if (trim(this.data.content) === '') {
        this.$message.error(this.$t('questionBlank') as string)
        return
      }
      this.data.answers = filter(this.data.answers, value => {
        return trim(value.content) !== ''
      })
      forEach(this.data.answers, value => {
        if (includes(value._id, 'fake_')) {
          delete value._id
        }
      })
      this.data.parentId = this.parentId
      this.$emit('submit', this.data)
    }

    handleCancel() {
      this.$emit('close')
    }

    mounted() {
      this.setData()
    }
  }
