
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { AnswerInterface, QuestionType } from '@/interface/BaseInterface'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'
  import { findIndex } from 'lodash'

  @Component({
    components: { BaseEditor }
  })
  export default class AnswerEdit extends Vue {
    @Prop({ required: true, type: Object }) data!: AnswerInterface

    isEdit = false

    get showAction() {
      return findIndex([QuestionType.TRUE_FALSE, QuestionType.TEXT_ONLY], value => value === this.data.type) === -1
    }

    get showCheckbox() {
      return findIndex([QuestionType.SHORT_ANSWER, QuestionType.FILL_IN_MULTIPLE], value => value === this.data.type) === -1
    }

    get onlyText() {
      return findIndex([QuestionType.TRUE_FALSE], value => value === this.data.type) !== -1
    }

    handleEdit() {
      this.isEdit = true
    }

    handleChange(text: string) {
      this.data.content = text
    }

    handleRemove() {
      this.$emit('remove', this.data._id)
    }

    handleCheckCorrect() {
      this.$emit('correctChange', {
        _id: this.data._id,
        isCorrect: !this.data.isCorrect
      })
    }
  }
