
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface, QuestionType } from '@/interface/BaseInterface'
  import ChildQuestionPreview from './ChildQuestionPreview.vue'
  import getApi from '@/api'
  import { assign, filter, findIndex } from 'lodash'
  import QuestionApi from '@/modules/questions/api/questionApi'

  const questionApi: QuestionApi = getApi('question')

  @Component({
    components: { ChildQuestionPreview }
  })
  export default class ChildQuestion extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface

    questionChild: QuestionInterface[] = []

    isLoading = false

    async handleCreate() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        const result = await questionApi.create({
          type: QuestionType.MUTILCHOICE,
          name: 'New Child Question',
          content: '',
          parentId: this.data._id
        })
        this.questionChild.push(result)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }

    handleCreateGroup() {
      console.log('123')
    }

    async handleSubmit(data: QuestionInterface) {
      console.log('data', data)
      try {
        await questionApi.update(data._id, data)
        const index = findIndex(this.questionChild, value => {
          return value._id === data._id
        })
        if (index !== -1) {
          this.questionChild[index] = assign(this.questionChild[index], data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    async handleRemove(data: QuestionInterface) {
      try {
        console.log(data)
        await questionApi.delete(data._id)
        this.questionChild = filter(this.questionChild, value => {
          return value._id !== data._id
        })
      } catch (error) {
        console.log(error)
      }
    }

    mounted() {
      this.questionChild = this.data.childQuestions || []
    }
  }
