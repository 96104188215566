
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface, QuestionType } from '@/interface/BaseInterface'
  import CalculatedQuestion from './type/CalculatedQuestion.vue'
  import EssayQuestion from './type/EssayQuestion.vue'
  import TrueFalse from './type/TrueFalse.vue'
  import FileUploadQuestion from './type/FileUploadQuestion.vue'
  import MatchingQuestion from './type/MatchingQuestion.vue'
  import MultipleAnswers from './type/MultipleAnswers.vue'
  import MultipleBlanks from './type/MultipleBlanks.vue'
  import MultipleDropdowns from './type/MultipleDropdowns.vue'
  import MutipleChoice from './type/MutipleChoice.vue'
  import NumericalQuestion from './type/NumericalQuestion.vue'
  import ShortAnswer from './type/ShortAnswer.vue'
  import TextOnlyQuestion from './type/TextOnlyQuestion.vue'
  import { findIndex, forEach, size } from 'lodash'

  @Component({
    components: {
      CalculatedQuestion,
      EssayQuestion,
      TrueFalse,
      FileUploadQuestion,
      MatchingQuestion,
      MultipleAnswers,
      MultipleBlanks,
      MultipleDropdowns,
      MutipleChoice,
      NumericalQuestion,
      ShortAnswer,
      TextOnlyQuestion
    }
  })
  export default class QuestionPreview extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface
    @Prop({ required: false, type: Number }) index!: number

    get answerComponent() {
      const component: Record<QuestionType, any> = {
        multiple_choice_question: 'MutipleChoice',
        true_false_question: 'TrueFalse',
        short_answer_question: 'ShortAnswer',
        fill_in_multiple_blanks_question: 'MultipleBlanks',
        multiple_answers_question: 'MultipleAnswers',
        multiple_dropdowns_question: 'MultipleDropdowns',
        // matching_question: 'MatchingQuestion',
        // numerical_question: 'NumericalQuestion',
        // calculated_question: 'CalculatedQuestion',
        essay_question: 'EssayQuestion',
        file_upload_question: 'FileUploadQuestion',
        text_only_question: 'TextOnlyQuestion'
      }
      return component[this.data.type] || null
    }

    get isShowQuestion() {
      return (
        findIndex(
          [QuestionType.FILL_IN_MULTIPLE, QuestionType.MUTILPLE_DROPDOWN, QuestionType.SHORT_ANSWER, QuestionType.ESSAY, QuestionType.TEXT_ONLY, QuestionType.FILE_UPLOAD],
          value => {
            return value === this.data.type
          }
        ) === -1
      )
    }

    get numKey() {
      return this.data.type === QuestionType.TEXT_ONLY ? this.getTotalQuestion(this.data.childQuestions) : size(this.data.keys)
    }

    getTotalQuestion(questions: QuestionInterface[] | undefined) {
      if (!questions) {
        return 0
      }
      let total = 0
      forEach(questions, value => {
        if (value.type === QuestionType.TEXT_ONLY) {
          total = total + this.getTotalQuestion(value.childQuestions)
        } else {
          const a = size(value.keys)
          total = total + (a === 0 ? 1 : a)
        }
      })
      return total
    }

    handleGetAnswer(data: any) {
      console.log(data)
    }

    created() {
      forEach(this.data.answers, value => {
        value.questionId = this.data._id
      })
    }
  }
