
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
  import { QuestionInterface, QuestionType, AnswerInterface } from '@/interface/BaseInterface'
  import AnswerEdit from '../answers/AnswerEdit.vue'
  import { filter, unionBy, forEach, size, findIndex } from 'lodash'

  @Component({
    components: { AnswerEdit }
  })
  export default class ListAnswers extends Vue {
    @Prop({ required: true, type: Object }) question!: QuestionInterface
    @Prop({ required: true, type: String }) type!: QuestionType

    listAnswers: AnswerInterface[] = []
    keySelect = ''

    get showAdd() {
      return findIndex([QuestionType.TRUE_FALSE, QuestionType.TEXT_ONLY], value => value === this.type) === -1
    }

    get showAnswerWithKey() {
      return findIndex([QuestionType.FILL_IN_MULTIPLE, QuestionType.MUTILPLE_DROPDOWN], value => value === this.type) !== -1
    }

    get getListAnswerByKey() {
      return filter(this.listAnswers, value => {
        return value.key === this.keySelect
      })
    }

    get noAnswer() {
      return findIndex([QuestionType.ESSAY, QuestionType.FILE_UPLOAD], value => value === this.type) !== -1
    }

    @Watch('type', { immediate: false }) watchType(new_: QuestionType) {
      if (new_) {
        this.init()
      }
    }

    @Watch('question', { immediate: true, deep: true }) watchQuestion() {
      this.$forceUpdate()
    }
    @Watch('question._id', { immediate: true }) watchQuestionId(new_: string) {
      if (new_ !== '') {
        this.listAnswers = this.question.answers || []
        if (size(this.listAnswers) === 0) this.handleAdd()
        if (size(this.question.keys) > 0) {
          // @ts-ignore
          this.keySelect = this.question.keys[0]
          this.handleChangeKey()
          this.$forceUpdate()
        }
      }
    }

    @Watch('listAnswers', { immediate: true, deep: true }) watchListAnswer(new_: AnswerInterface[]) {
      this.$emit('changeAnswer', new_)
    }

    init() {
      this.listAnswers = []
      switch (this.type) {
        case QuestionType.MUTILCHOICE:
        case QuestionType.SHORT_ANSWER:
        case QuestionType.MUTILPLE_ANSWERS:
          for (let i = 0; i < 4; i++) {
            this.listAnswers.push(this.fakeData(this.type, i === 0))
          }
          break
        case QuestionType.TRUE_FALSE:
          this.listAnswers.push(this.fakeData(this.type, true))
          this.listAnswers.push(this.fakeData(this.type, false))
          break
        case QuestionType.FILL_IN_MULTIPLE:
          this.listAnswers.push(this.fakeData(this.type, true))
          this.listAnswers.push(this.fakeData(this.type, true))
          break
        case QuestionType.MUTILPLE_DROPDOWN:
          this.listAnswers.push(this.fakeData(this.type, true))
          this.listAnswers.push(this.fakeData(this.type, false))
          break
        default:
          this.listAnswers = []
          break
      }
    }

    fakeData(type: QuestionType, isCorrect = false) {
      const result: AnswerInterface = {
        _id:
          'fake_' +
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15),
        isCorrect,
        content: '',
        type: type
      }

      if (type === QuestionType.TRUE_FALSE) {
        result.content = isCorrect ? 'TRUE' : 'FALSE'
      } else if (type === QuestionType.FILL_IN_MULTIPLE || type === QuestionType.MUTILPLE_DROPDOWN) {
        result.key = this.keySelect
      }

      return result
    }

    handleChangeCorrect(data: { _id: string; isCorrect: boolean }) {
      console.log(data)
      if (this.type === QuestionType.MUTILCHOICE || this.type === QuestionType.TRUE_FALSE) {
        forEach(this.listAnswers, value => {
          value.isCorrect = false
          if (value._id === data._id) {
            value.isCorrect = true
          }
        })
      } else if (this.type === QuestionType.MUTILPLE_ANSWERS) {
        forEach(this.listAnswers, value => {
          if (value._id === data._id) {
            value.isCorrect = data.isCorrect
          }
        })
      } else if (this.type === QuestionType.MUTILPLE_DROPDOWN) {
        forEach(this.listAnswers, value => {
          if (value.key === this.keySelect) {
            value.isCorrect = false
            if (value._id === data._id) {
              value.isCorrect = true
            }
          }
        })
      }
    }

    handleRemove(id: string) {
      if (size(this.listAnswers) === 1) {
        return
      }
      this.listAnswers = filter(this.listAnswers, value => {
        return value._id !== id
      })
    }

    handleChangeKey() {
      forEach(this.listAnswers, value => {
        if (value.key === '') {
          value.key = this.keySelect
        }
      })
      if (size(this.getListAnswerByKey) === 0) {
        this.handleAdd()
        forEach(this.listAnswers, value => {
          if (value.key === '') {
            value.key = this.keySelect
          }
        })
      }
      console.log(this.getListAnswerByKey)
    }

    handleAdd() {
      this.listAnswers = unionBy(this.listAnswers, [this.fakeData(this.type)], '_id')
    }

    created() {
      this.init()
    }
  }
